export default {
  methods: {
    showError(e) {
      // console.log(e);
      const error =
        e &&
        e.response &&
        e.response.data &&
        e.response.data['NeutronError'] &&
        e.response.data['NeutronError'].message
          ? e.response.data['NeutronError'].message
          : e.message;
      // const title = e.response.data['NeutronError'].type;
      // console.log(e);
      this.$modals.open({
        name: 'Error',
        // title: this.$t('error'),
        // title: title,
        text: error,
        footer: {
          centered: true,
          cancel: {
            props: { title: this.$t('ok') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
    showErrorS3(e) {
      const error = e?.message ? e?.message : e;
      const title = e?.type;
      this.$modals.open({
        name: 'Error',
        // title: this.$t('error'),
        title: title,
        text: error,
        footer: {
          centered: true,
          confirm: {
            props: { title: this.$t('confirm') },
            on: { click: () => this.$modals.close() },
          },
        },
      });
    },
  },
};
